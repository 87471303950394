.header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    z-index: 100;

    .logo {
        height: 64px;
        margin-left: 50px;
    }

    .svg {
        color: rgb(31, 80, 20);
    }
}