.title {
    font-size: 24px;
    margin-bottom: 10px;
    width: 60%;
    color: rgb(31, 80, 20);
    font-weight: 600;
}

.share {
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    div {
        margin-right: 20px;        
    }
    a {
        font-size: 24px;

        .icon {
            color: #29a60d
        }
    }
}