.scoreContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 24px;
    }
    
    p {
        font-size: 16px;
    }
}