.quizQty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 10%;
    width: 80%;

    .title {
        font-size: 24px;
        margin-bottom: 10px;
        width: 60%;
        color: rgb(31, 80, 20);
        font-weight: 600;
    }

    h1 {
        font-size: 18px;
    }

    span {
        margin-bottom: 10px;
    }

    input {
        border: 1px solid grey;
        width: 40px;
        margin-bottom: 10px;
    }

    :first-child {
        margin-right: 5px;
    }
}