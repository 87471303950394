.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 20px;

    .title {
        font-size: 24px;
        margin-bottom: 10px;
        width: 60%;
        color: rgb(31, 80, 20);
        font-weight: 600;
    }

    .h2 {
        font-style:italic;
        font-size: 18px;
    }

    .h3 {
        font-size: 16px;
        margin-top: 10px;
    }

    .feature {
        display: flex;
        flex-direction: column;
        width: 60%;

        .featureContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .icon, span {
                font-size: 48px;
                margin-right: 20px;
                color: rgb(31, 80, 20);
            }
        
            .description {
                text-align: center;
            }
        
        }
    }

    p {
        font-size: 14px;
        width: 80%;
    }
}

@media only screen and (max-width: 375px) {
    .about {
        width: 100%;
        margin-left: 0px;

        p {
            width: 100%;
        }

        .feature {
            width: 100%;
        }
    }
}