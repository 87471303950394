.quizItemContainer {
    margin-top: 10px;
    min-height: 200px;

    .answerSection {
        display: flex;
        flex-direction: column;
        height: 30px;
    }

    .correctAnswer {
        color: green;
    }
    
    .wrongAnswer {
        color: red;
    }
}