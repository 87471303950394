.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 20px;

    .contactTitle {
        font-size: 24px;
        margin-bottom: 10px;
        color: rgb(31, 80, 20);
        font-weight: 600;
        width: 60%;
    }
    p {
        display: flex; 
        justify-content: space-around;
        align-items: center;

        input, textarea {
            border: 1px solid #333333;
            text-align: left;
            padding-left: 5px;
            margin-left: 10px;
            width: 300px;
            resize: none;
        }
        label {
            display: flex;
            width: 75px;
            align-items: center;
        }
        .submitBtn {
            width: 100px;
            text-align: center;
            background-color: #29a60d;
            border-color: #29a60d;
            color: white;
        }
    }

}

@media only screen and (max-width: 375px) {
    .contact {
        .contactTitle {
            font-size: 24px;
            margin-bottom: 10px;
            width: 100%;
        }

        p {
            input, textarea {
                margin-left: 10px;
                width: 200px;
                resize: none;
            }
        }
    }
}