@import '~antd/dist/antd.css';
@import './global.scss';

.App {
  text-align: center;
}

.ant-modal-body {
  padding: 12px 24px !important;
}

.layout {
  background-color: whitesmoke !important;
}

.ant-collapse-header {
  width: 288px;
}

.ant-card-meta-description {
  color: #333333 !important;
  margin-top: 10px;
}

.ant-menu-submenu-title {
  padding-left: 10px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (min-width: 375px) {
  .ant-collapse-header {
    width: 800px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
