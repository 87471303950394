.contact {
    a {
        color: rgba(0, 0, 0, 0.85);
        margin-right: -5px;
    }
}

.disclaimer {
    svg {
        margin-right: -5px;
        margin-left: 15px;
    }
}