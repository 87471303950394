.favorites {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 20px;

    .h1 {
        font-size: 24px;
        color: rgb(31, 80, 20);
        font-weight: 600;
        width: 60%;
    }
    
    .icon {
        margin-left: 10px;
        font-size: 20px;
        color: rgb(31, 80, 20);
    }
}

a {
    color: #272727;
}