@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Montserrat:wght@100;400&family=Open+Sans:wght@400;600&family=PT+Serif&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

.heading {
    font-size: 48px;
    color: #272727;
}

.responsiveImg {
    width: 100%;
    height: auto;
}

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h2 {
        font-size: 20px;
    }
    .quizQty {
        margin: 5px 0;

        input {
            border: 1px solid grey;
            width: 40px;

        }

        :first-child {
            margin-right: 5px;
        }
    }
}

.meta {
    margin-top: 10px;
    color: "#333333";
}

@media only screen and (max-width: 375px) {
    .heading {
        font-size: 32px;
    }
}
